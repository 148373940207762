<script setup lang="ts">
import { reactive, ref, onMounted } from 'vue';
import { useClickOutside } from '../composable/useClickOutside';

const list = reactive([
  { name: 'Главная', url: '/' },
  { name: 'Новости', url: '/news' },
  { name: 'Продукция', url: '/products' },
  { name: 'Обучение', url: '/education' },
  { name: 'Контакты', url: '/contacts' },
]);

const highlightedLinks = reactive([{ name: 'Заявка', url: '/request' }]);

const active = ref(false);
const button = ref<HTMLButtonElement | null>(null);
const menu = ref<HTMLDivElement | null>(null);

useClickOutside([button, menu], () => {
  active.value = false;
});

function toggle() {
  active.value = !active.value;
}
</script>
<template>
  <button
    class="ml-3 mr-2 cursor-pointer text-white"
    ref="button"
    aria-controls="hamburger-menu"
    :aria-expanded="active"
    @click="toggle"
    :aria-label="active ? 'Close menu' : 'Open menu'"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-7 w-7"
      v-show="!active"
      aria-hidden="true"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M4 6h16M4 12h16M4 18h16"
      />
    </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-7 w-7"
      v-show="active"
      aria-hidden="true"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  </button>

  <div
    ref="menu"
    id="hamburger-menu"
    role="menu"
    class="fixed left-0 flex h-screen w-3/4 transform flex-col gap-2 bg-black px-5 pt-10 transition-all"
    :class="{ '-translate-x-full': !active }"
    :aria-hidden="!active"
  >
    <div
      class="cursor-pointer rounded-md px-4 py-2 transition-all"
      v-for="item in list"
      :key="item.name"
    >
      <a
        :href="item.url"
        class="text-white visited:text-white hover:text-white focus:text-white"
      >
        {{ item.name }}
      </a>
    </div>
    <div
      class="cursor-pointer rounded-md bg-mango-600 px-4 py-2 transition-all hover:bg-mango-700"
      v-for="item in highlightedLinks"
      :key="item.name"
    >
      <a
        :href="item.url"
        class="text-white visited:text-white hover:text-white focus:text-white"
      >
        {{ item.name }}
      </a>
    </div>
  </div>
</template>
<style></style>
